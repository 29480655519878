export const HOME = "/";

export const ABOUT_PAGE = "/about";
export const LANDING_PAGE = "/landing";
export const USER_DETAILS_PAGE = "/user-details";
export const USER_DASHBOARD_PAGE = "/user-dashboard";
export const USER_PROFILE_PAGE = "/user-dashboard/profile";
export const USER_SETTINGS_PAGE = "/user-dashboard/settings";
export const EVENTS_PAGE = "/events";
export const USER_EVENTS_PAGE = "/user-dashboard/events";
export const EVENT_DETAILS = "/event-details";
export const BMC_REGIST = "/bmc/register";
export const BMC_REGIST_SUMMARY = "/bmc/summary";
export const FCEO_REGIST = "/fceo/register";
export const FCEO_REGIST_SUMMARY = "/fceo/summary";
export const IBPC_REGIST = "/ibpc/register";
export const IBPC_REGIST_SUMMARY = "/ibpc/summary";
export const IBCC_REGIST = "/ibcc/register";
export const IBCC_REGIST_SUMMARY = "/ibcc/summary";
export const CHAMBERS_REGIST = "/chambers/register";
export const CHAMBERS_REGIST_SUMMARY = "/chambers/summary";
export const COMPVIS_REGISTRATION = "/comvis/register";
export const COMPVIS_REGIST_SUMMARY = "/comvis/summary";
export const INTERSUMMIT_REGISTRATION = "/intersummit/register";
export const INTERSUMMIT_REGIS_SUMMARY = "/intersummit/summary";
export const SUMMIT_REGIST = "/summit/check-registration-code";